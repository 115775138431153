/* 애니메이션 설정 */
@keyframes slideUp {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* 애니메이션이 적용될 요소 스타일 */
  .box {
    opacity: 0;
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .slide-up {
    animation: slideUp 1s ease-out forwards;
    opacity: 1;
  }
  